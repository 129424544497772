import React from "react"; // 
import useTheme from "@mui/system/useTheme";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";
import IconExternalLink from "../../_shared/iconExternalLink"
import SeparatorAbsSvg2 from "../../_shared/shaping/SeparatorAbsSvg2";


const _mainUp = () => {
  const theme = useTheme()
  const deviceSize = useBreakpoint()
  const optionsColorsSeparatorAbs = {
    gradientBgColor: theme.palette.primary2.main,
    fillColors: [ theme.palette.secondary.main, theme.palette.warning.main, theme.palette.primary2.dark ], strokeColor: theme.palette.primary2.contrastText,
  }
  return (
    <Container maxWidth="lg" className="contLinkTempl container" >
      <div>
        Vytvorenie web stránok v javascripte z nuly je extrémne zdĺhavé. Čím má tvorca webstránky k dispozícii rozsiahlejši základ a väčší zásobník komponentov riešiaci požadovaný vzhľad alebo funkcionality, tak dokáže čas skrátiť. Profesionálne šablóny web stránok dokážu dramaticky urýchliť vytvorenie webstránky. Tieto nie sú klikacie ako sú vo Wordpresse, kde cieľom bolo nechať vytvoriť webstránky aj laikom. Po zakúpení šablóny treba prispôsobiť vnútri javascriptový kód, aby sa adaptoval na zákazníkove texty, obrázky, videá. Javascriptová šablóna je základ webstránky, ale je ho možné úplne prispôsobovať podľa vašich želaných funkcionalít - pridaním alebo zmenou javascriptu.
        Preto, ak chcete super webstránky za výbornú cenu požiadajte o vytvorenie web stránok z ponúkaných profesionálnych web šablón príp. už predtým vytvorených web stránok. Pozrite si možné šablóny.
      </div>
      <div>
        choosing the best React website template:

        Design quality
        Code quality/code cleanliness
        Documentation quality
        Ease of installation
        Update frequency
        Variety of components
        Maintenance and quality of support
        Clients
        Company experience
        Price
      </div>
      <div>
        Profesionálne web šablóny sú vytvorené firmami, jednotlivcami, ktorí sa roky venujú tvorbe web stránok a svoje skúsenosti s HTML a CSS a rôznymi web technológiami pretavili do vytvorenia maximálne vyladených web stránok z pohľadu príjemného vzhľadu, optimalizovanej rýchlosti, animačných prvkov, dokonalej responzívnosti pre rôzne zaridenia, možného prispôsobenia a rozšíriteľnosti.
      </div>
      <SeparatorAbsSvg2 name="sepNormalRelDblFullHorizontal1"
        optionsSep={{
          ...optionsColorsSeparatorAbs,
          isRotateYReverse: true, heightsWanted: [ 8, 16, 32, 48, 64 ], key: "sepSablonyIntro"
        }} />
      <Typography>
        Najaktuálnejšie dostupné web šablóny s Gatsby nájdete na
      </Typography>
      <Typography key="linkSablony">
        <a href="https://themeforest.net/search/gatsby?sort=date#content" rel="nofollow noopener noreferrer" target="_blank" className="extLink" >
          web šablóny s Gatsby &nbsp;<IconExternalLink />
        </a>
      </Typography>
      <SeparatorAbsSvg2 name="sepNormalRelDblFullHorizontal1" optionsSep={{
        ...optionsColorsSeparatorAbs,
        isRotateYReverse: true, heightsWanted: [ 8, 16, 32, 48, 64 ], key: "sepSablonyAktualne"
      }} />
      <Typography key="linkTozi">
        <a href="https://themeforest.net/item/tozi-react-gatsby-blog-template/36474286" rel="nofollow noopener noreferrer" target="_blank" className="extLink" >
          Tozi - Gatsby React Blog & Magazine Template&nbsp;<IconExternalLink />
        </a>
      </Typography>
      <Typography>
        Všeobecne použiteľná web šablóna, pre blog aj časopisy. Môžte napr. ak sú články k produktom mať automatické výpisy pre aktuálne počty článkov podľa tagov (alebo kategórií), najnovšie články podľa jednotlivých tagov.

        Regular Updates, Top Quality Support from the Author
      </Typography>
      <Typography>
        Využité technológie Gatsby, React, Strapi CMS, GraphQL, PostgreSQL, Cloudinary, Bootstrap & Sass.
        Disqus pre pridávanie komentárov pre prihlásených užívateľov, React Markdown pre pridávanie článkov cez markdown, využívané ikony z Boxicons, Animate CSS pre animačné efekty
      </Typography>
      <Typography key="linkToziDemo">
        <a href="https://tozi-gatsby.envytheme.com/" rel="nofollow noopener noreferrer" target="_blank" className="extLink" >
          Demo verzia na &nbsp;<IconExternalLink />
        </a>
      </Typography>
      Demo verzia na
    </Container>)
}

export default _mainUp